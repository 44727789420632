h1 {
    font-family: $heading-font;
    font-size: rem-calc(35);
    line-height: rem-calc(35);

    @include breakpoint(large) {
        font-size: rem-calc(50);
        line-height: rem-calc(50);
    }
}

h2 {
    font-family: $heading-font;
    font-size: rem-calc(27);
    line-height: rem-calc(27);

    @include breakpoint(large) {
        font-size: rem-calc(30);
        line-height: rem-calc(30);
    }
}

h3 {
    font-family: $heading-font;
    font-family: $body-font;
    font-size: rem-calc(14);
    line-height: rem-calc(17);
}

h4 {
    font-family: $script-font;
    font-size: rem-calc(62);

    @include breakpoint(large) {
        font-size: rem-calc(90)
    }
}

p {
    font-family: $body-font;
    font-size: rem-calc(16);
    line-height: rem-calc(26);
}

.intro p, .home__intro p {
    font-family: $body-font;
    font-size: rem-calc(16);
    line-height: rem-calc(24);

    @include breakpoint(large) {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
    }
}

.body-copy {
    font-family: $body-font;
    font-size: rem-calc(16);
    line-height: rem-calc(26)
}

.smallest {
    font-family: $body-font;
    font-size: rem-calc(10);
    font-weight: 500;
    letter-spacing: 1px;
}

.bg-img {
    width: 100%;
    height: 100%;
    object-fit: auto;
}