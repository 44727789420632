.terms {
	text-align: center;
	margin-bottom: 0px;
	background-color: get-color('beige');
	
	&__inner {
		max-width: 1260px;
		width: 100%;
		margin: 0 auto;
		padding: 50px;

		@include breakpoint(small only) {
				padding: 25px;
		}
	}

	&__heading {

		h2 {
			margin-bottom: 25px;
			font-family: $body-font;
			font-size: rem-calc(14);
			line-height: rem-calc(30);
			font-weight: 500;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}

	&__content {
		max-width: 575px;
		width: 100%;
		margin: 0 auto;

		&-top {
			margin-bottom: 25px;

			p {
				margin-bottom: 0px;
			}
		}
	}
	
	&__content-more .accordionButton {
		display: inline-block;
		position: relative;
		font-family: $body-font;
		font-size: rem-calc(14);
		line-height: rem-calc(30);
		text-transform: uppercase;
		color: #fff;
		font-weight: 500;
		padding-right: 50px;
		min-width: 150px;

		&::after {
			content: '';
			width: 30px;
			height: 30px;
			position: absolute;
			right: 0;
			transform: rotate(45deg);
			background: url('img/gleddoch-close-white.svg') no-repeat center;
			background-size: 30px;
			transition: all 0.4s ease-in-out;
		}
		
		&.on::after {
			transform: rotate(0deg);
		}
	}

	.accordionContent {

		p {
			margin-bottom: 25px;
		}
	}
}