.social-wall {
	text-align: center;
	padding: 75px 0 20px;

	&__feed {
		max-width: 1103px;
		margin: 0 auto;
		padding-bottom: 0 !important;
	}

	h3 {
		color: get-color('gold');
		margin-bottom: 50px;
		font-size: rem-calc(30);
		line-height: rem-calc(35);
		font-family: $heading-font;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
}

.crt-widget-grid .crt-grid-post {
	@include breakpoint(medium) {
		width: 23% !important;
		margin: 0 1% !important;
	}
}

.crt-grid-post .crt-social-icon {
	top: 8px !important;
	bottom: initial !important;
}