.form {
	label.error {
		color: #a70000;
		display: block;
		margin-bottom: 0px;
		transform: translateY(-15px);
		font-family: $body-font;
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	
	&__checkbox {
		position: relative;
		text-align: left;
		max-width: 200px;
		
		input[type="checkbox"] {
			float: left;
		}
		
		label {
			margin-left: 20px;
			display: block;
		}
	}
}