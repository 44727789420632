.menus {
	margin-bottom: 0px;
	
	&__heading {
		h2 {
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 25px;
		}
	}

	&__image {

		&-slide {
			position: relative;
			max-height: 560px;

			img {
				top:0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				max-height: 560px;
			}
		}

		.slick-prev,
		.slick-next {
			position: absolute;
			bottom: 50px;
			z-index: 1;
			text-indent: -12345px;
			width: 42px;
			height: 8px;
			transition: all 0.3s ease-in-out;
		}
		
		.slick-prev {
			right: 94px;
			background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
			background-position: left 10px center;

			@include breakpoint(small only) {
				bottom: 25px;
				right: 94px;
			}
			
			&:hover {
				background-position: center;
			}
		}

		.slick-next {
			right: 42px;
			background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
			background-position: right 10px center;

			@include breakpoint(small only) {
				bottom: 25px;
				right: 42px;
			}
			
			&:hover {
				background-position: center;
			}
		}
	}
	
	&__content {
		background: url('img/gleddoch-diamond-pattern-dark.png');
		padding: 25px 5%;
		color: #fff;

		@include breakpoint(small only) {
			text-align: center;
			padding: 25px;
		}
	}

	&__list {
		list-style: none;
		margin: 0 0 50px;
		
		li {
			
			a {
				color: #fff;
				font-family: $script-font;
				font-size: rem-calc(35);
				line-height: rem-calc(43);
				text-transform: lowercase;
				letter-spacing: 1px;
				transition: color 0.3s ease-in-out;
				
				&:hover,
				&:active {
					color: get-color('beige');
				}
				
				span {
					display: none;
				}
			}
		}
	}
	
	&__times {
		
		h3 {
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 25px;
			font-size: rem-calc(30);
			line-height: rem-calc(25);
			letter-spacing: 1px;
			font-family: $heading-font;
			margin: 0 0 25px;
		}
		
		ul {
			list-style: none;
			margin: 0;

			li {
				font-family: $body-font;
				font-size: rem-calc(14);
				line-height: rem-calc(43);
				font-weight: 500;
				letter-spacing: 1px;
			}
		}
	}
}