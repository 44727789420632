.function-room {
	&__capacities {
		margin: 10px 0 20px;
		list-style-type: none; 
		
		@include breakpoint(medium) {
			column-count: 2;
			column-gap: 40px;
		}
		
		li {
			margin-bottom: 10px;
			clear: both;
			
			img {
				width: 36px;
				height: 36px;
			}
		}
		
		&-quantity {
			float: right;
		}
	}
	
	&__wysiwyg {
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
		padding: 20px;
		text-align: center; 
	}
}