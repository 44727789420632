.header {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100%;
	transition: background-color 0.3s ease-in-out;
	height: 140px;
	background-color: $body-background;
	
	
	@include breakpoint(small only) {
		height: 106px;
	}
	
	&__inner {
		position: relative;
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}
	
	&__open-off-canvas {
		position: absolute;
		top: 55px;
		left: 25px;
		background: url('img/gleddoch-burger-black.svg') no-repeat center;
		background-repeat:repeat-x;
		text-indent: 40px;
		border: none;
		width: 30px;
		padding: 0;
		cursor: pointer;
		transition: all 300ms ease-in-out;
		color: #191919;
		text-transform: uppercase;
		font-family: $body-font; 
		font-weight: 800;

		&:hover, &:focus {
			width: 38px;
			text-indent: 50px;
		}

		@include breakpoint(small only) {
			left: 25px;
		}
	}
	
	&__open-booking {
		position: absolute;
		top: 50px;
		right: 25px;
		text-transform: uppercase;
		font-family: brandon-grotesque;
		font-weight: 700;
		font-size: rem-calc(13);
		line-height: rem-calc(19);
		letter-spacing: 2px;
		border: none;
		box-shadow: none;
		color: #fff;
		background-color: get-color('gold');
		text-decoration: none;
		padding: 5px 30px;

		&::after, &::before {
			content: none;
		}

		&:hover, &:focus {
			background-color: get-color('bronze');
			color: #fff;
		}
		
		@at-root .header--off-canvas #{&} {
			display: none;
		}

		@media screen and (max-width: $offcanvas-breakpoint) {
			position: fixed;
			top: auto;
			bottom: 0;
			right:0;
			width: 50%;

			background-color: #fff !important;
			color: get-color('gold') !important;
			border: 1px solid get-color('gold') !important;
			padding: 12px 30px;
			padding-bottom: env(safe-area-inset-bottom);
			margin-bottom: 0;
		}

		// @include breakpoint(small only) {
		// 	position: fixed;
		// 	top: auto;
		// 	bottom: 0;
		// 	right:0;
		// 	width: 50%;

		// 	background-color: #fff !important;
		// 	color: get-color('gold') !important;
		// 	border: 1px solid get-color('gold') !important;
		// 	padding: 12px 30px;
		// 	padding-bottom: env(safe-area-inset-bottom);
		// 	margin-bottom: 0;
		// }
	}
	
	&__open-booking-mobile {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 50%;
		z-index: 1500;
		margin: 0;
		display: none;
		
		
		@at-root .header--off-canvas #{&} {
			display: block;
		}	
	}
	
	&__mobile-call {
		position: absolute;
		top: 40px;
		right: 25px;
		width: 30px;
		height: 30px;
		text-indent: -12345px;
		margin: 0;
		padding: 0;
		background: none transparent;
		display: block;

		&::after, &::before {
			content: none;
		}
		
		@at-root .header--off-canvas #{&} {
			display: block;
		}
		
		img,
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			path {
				stroke: #000;
			}
		}
	}
	
	&__logo {
		width: 100%;
		max-width: 150px;
		margin: 0 auto;
		padding-top: 14px;

		@include breakpoint(small only) {
			max-width: 115px;
		}
		
		&--transparent {
			display: none;
		}
		
		img {
			width: 100%;

		}
	}
	
	&__top-nav {
		position: absolute;
		top: 20px;
		right: 25px;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			display: none;
		}
		
		ul {
			margin: 0;
			list-style-type: none;
			line-height: 1;
		}
		
		li {
			display: inline;
			margin-left: 10px;
			
			&:first-child {
				margin-left: 0;
			}

			a {
				color: get-color('dark-grey');
				text-transform: uppercase;

				&:hover, &:focus {
					color: get-color('gold');
				}
			}
		}
	}

	&__sub-nav {
		position: absolute;
		z-index: 500;
		top: 51px;
		left: 0;
		width: 200px;
		padding: 10px;
		background-color: $body-background;
		border: 1px solid #c8c8c8;
		text-align: left;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s ease-in-out;
				
		&.open {
			visibility: visible;
			opacity: 1;
		}
		
		ul {
			margin: 0 0 15px;
			padding: 0 0 10px 10px;
		}
		
		li {
			display: block;		
		}
	}

	&__secondary-cta {

		position: absolute;
		top: 50px;
		right: 200px;
		text-transform: uppercase;
		font-family: brandon-grotesque;
		font-weight: 700;
		font-size: rem-calc(13);
		line-height: rem-calc(19);
		letter-spacing: 2px;
		border: 1px solid get-color('gold');
		box-shadow: none;
		color: get-color('gold');
		background-color: transparent;
		text-decoration: none;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 45px;
		min-width: 160px;

		&::after, &::before {
			content: none;
		}


		@media screen and (max-width: $offcanvas-breakpoint) {
			display: none;
		}
	}


	
	
	//////////////////////
	// Styles that are applied to only the transparent header
	//////////////////////
	&--transparent {
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.5) 100%);
		
		.header {
			&__logo {
				&--transparent {
					display: block;
				}
				
				&--normal {
					display: none;
				}
			}

			&__open-off-canvas {
				background: url('img/gleddoch-burger-white.svg') no-repeat center;
				background-repeat: repeat-x;
				color: #fff;

				&:hover, &:focus {
					// padding-right: 60px;
					width: 38px;
					text-indent: 50px;
				}
			}

			&__open-booking {
				color: get-color('gold');
				background-color: #fff;

				&:hover, &:focus {
					color: #fff;
					background-color: get-color('gold');
				}
			}

			&__mobile-call {
				
				path {

					stroke: #fff;
				}
			}

			&__top-nav {

				ul {

					li {

						a {
							color: #fff;

							&:hover, &:focus {
								color: get-color('gold');
							}
						}
					}
				}
			}

			&__secondary-cta {
				color:#fff;
				border: 1px solid #fff;
				transition: all 0.3s ease-in-out;

				&:hover {
					color:get-color('gold');
					border: 1px solid get-color('gold');
				}
			}
		}
	}
}

#book-now-accordion  {
	
	@media screen and (max-width: $offcanvas-breakpoint) {
		display: none;
	}
		
	
	.header__open-booking, .header__open-booking:visited, .header__open-booking:hover, .header__open-booking:active {
		min-width: 160px;
	    text-transform: uppercase;
	    font-family: brandon-grotesque;
	    font-weight: 700;
	    font-size: .8125rem;
	    line-height: 45px;
	    letter-spacing: 2px;
	    border: none;
	    box-shadow: none;
	    text-decoration: none;
	    padding: 0 0 0 0;
	    text-align: center;
	    height: 45px;
	    
	}
	
	.header__open-booking.on {
		background-image: url('img/book-close.png');
		background-repeat: no-repeat;
		background-position: 20px center;
	}
	
	
	.accordionContent {	
		display: none;	
		position: absolute;
	    right: 25px;
	    top: 95px;
	    border-bottom: 1px solid #bfa677;
		
	}
	
	
	.book-item, .book-item:visited, .book-item:hover, .book-item:active {
		width: 200px;
	    text-transform: uppercase;
	    font-family: brandon-grotesque;
	    font-weight: 700;
	    font-size: .8125rem;
	    line-height: 45px;
	    letter-spacing: 2px;
	    border: none;
	    box-shadow: none;
	    text-decoration: none;
	    padding: 0 0 0 0;
	    text-align: center;
	    height: 45px;
		display: block;
		width: 200px;
		clear: both;
		background-color: #fff;
	    color: #000;
	    border-top: 1px solid #bfa677;
	}
	
	.book-item:hover {
		background-color: #f1ece1;
	}
	
	
}


#book-now-mobile {
	
	display: none;
	
	// @include breakpoint(small only) {
	// 	display: block;
	// }

	@media screen and (max-width: $offcanvas-breakpoint) {
		display: block;
	}



	.mobile-secondary-btn {
		position: fixed;
		top: auto;
		bottom: 0;
		left:0;
		width: 50%;
		background-color: #fff !important;
		color: get-color('gold') !important;
		border: 1px solid get-color('gold') !important;
		padding: 12px 30px;
		padding-bottom: env(safe-area-inset-bottom);
		margin-bottom: 0;
		text-align: center;
		line-height: 45px;
		padding: 0;
		height: 45px;

		a {
			font-family: $body-font;
			font-weight: 700;
			font-size: .8125rem;
			line-height: 1.1875rem;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: get-color('gold');
		}
	}
	
	
	.header__open-booking, .header__open-booking:visited, .header__open-booking:hover, .header__open-booking:active {
	    line-height: 45px;
	    padding: 0 0 0 0;
	    text-align: center;
	    height: 45px;
		width: 50%;
	    
	}
	
	
	.header__open-booking:hover {
		background-color: #bfa677;
	    color: #fff;
	}
	
	.header__open-booking.on {
		background-image: url('img/book-close.png');
		background-repeat: no-repeat;
		background-position: 20px center;
		background-color: #bfa677!important;
	    color: #fff!important;
	    bottom: 225px;
	}
	
	.book-now-mobile-content {
		display: none;
		width: 100vw;
		position: fixed;
	    bottom: 0;
	    border-top: 1px solid #bfa677;
		
	}
	
	
	.book-item, .book-item:visited, .book-item:hover, .book-item:active {
		width: 100vw;
	    text-transform: uppercase;
	    font-family: brandon-grotesque;
	    font-weight: 700;
	    font-size: .8125rem;
	    line-height: 45px;
	    letter-spacing: 2px;
	    border: none;
	    box-shadow: none;
	    text-decoration: none;
	    padding: 0 0 0 0;
	    text-align: center;
	    height: 45px;
		display: block;
		clear: both;
		background-color: #fff;
	    color: #000;
	    border-bottom: 1px solid #bfa677;
	}
	
	.book-item:hover {
		background-color: #f1ece1;
	}
	
	
}

.active {
	color: #ffffff;
    text-decoration: underline;
    text-underline-offset: 3px;
    outline: none;
}