.slider {
	position: relative;
	text-align: center;
	
	.slider__inner {
		width: 100vw;
		height: 75vh;
	}
	
	&--full-height {
		.slider__inner {
			width: 100vw;
			height: 100vh;
			
			@include breakpoint(small only) {
				height: calc(100vh - 75px);
			}
		}
	}
	
	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: no-repeat center center;
		background-size: cover;

		&-overlay {
			background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%);
			position: absolute;
			top: 0;right: 0;
			bottom: 0;
			left: 0;
		}
	}
	
	&__video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		
		iframe,
		video {
			position: absolute;
		}
	}
	
	&__caption {
		position: absolute;
		bottom: 50px;
		left: 8.3%;
		color: #fff;
		text-align: left;
		
		@include breakpoint(small only) {
			bottom: 10px;
		}
		
		h2 {
			font-size: rem-calc(34);
			line-height: rem-calc(34);
			font-weight: 500;
			text-transform:uppercase;
			margin-bottom: 58px;
			
			@include breakpoint(large) {
				font-size: rem-calc(50);
				line-height: rem-calc(50);
			}

			@include breakpoint(small only) {
				margin-bottom: 50px;
				max-width: 240px;
				width: 100%;
			}

			span {
				font-weight: 400;
				text-transform: initial;
				//font-family: $script-font;
				font-family: 'sacramento',sans-serif;
				font-size: rem-calc(140);

				@include breakpoint(small only) {
					font-family: $heading-font;
					font-size: rem-calc(34);
					line-height: rem-calc(34);
					text-transform: uppercase;
				}
			}
		}

		p {
			font-family: $body-font;
			font-size: rem-calc(16);
			line-height: rem-calc(24);
			margin-bottom: 25px;
			max-width: 410px;
			width: 100%;

			@include breakpoint(large) {
				font-size: rem-calc(20);
				line-height: rem-calc(30);
			}
		}

		a.button {
			color: #fff;
			margin-bottom: 0;
		}
		
		&-inner {
			width: 80%;
			max-width: 600px;
			margin: 0 auto;
		}
	}
	
	.reform-slider-arrows {
		margin: 0;
		list-style: none;
		
		.reform-prev,
		.reform-next {
			position: absolute;
			bottom: 50px;
			z-index: 1000;
			text-indent: -123456px;
			width: 42px;
			height: 8px;
			transition: all 0.3s ease-in-out;

			@include breakpoint(small only) {
				bottom: 25px;
			}
		}
		
		.reform-prev {
			right: 150px;
			background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
			background-position: left 10px center;

			@include breakpoint(small only) {
				right: 75px;
			}
			
			&:hover {
				background-position: center;
			}
		}
		
		.reform-next {
			right: 100px;
			background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
			background-position: right 10px center;

			@include breakpoint(small only) {
				right: 25px;
			}
			
			&:hover {
				background-position: center;
			}
		}
	}
	
	.reform-pagination {
		position: absolute;
		z-index: 1000;
		bottom: 50px;
		left: 0;
		right: 0;
		margin: 0;
		list-style-type: none;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			bottom: 80px;
		}
		
		button {
			cursor: pointer;
		}
		
		li {
			display: inline-block;
			margin: 0 7px;
			
			&.reform-pagination-dot button {
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background-color: #fff;
				border: 1px solid #fff;
				text-indent: -123456px;
				cursor: pointer;
				transform: translateY(-4px);
			}
			
			&.reform-pagination-dot button:hover,
			&.reform-pagination-dot.active button {
				background-color: transparent;
			}
		}
		
		.reform-pagination-prev,
		.reform-pagination-next {
			position: relative;
			text-indent: -123456px;
			width: 12px;
			height: 12px;
			cursor: pointer;
		}
		
		.reform-pagination-prev {
			@include arrow("left", #fff, #fff)
		}
		
		.reform-pagination-next {
			@include arrow("right", #fff, #fff)
		}
	}
	
	&__scroll {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		z-index: 1000;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			bottom: 60px;
		}
		
		button {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			text-indent: -123456px;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			
			@include arrow("down", #fff, #fff)
			
			&:hover,
			&:active {
				transform: translateY(5px);
			}
		}
	}
}


.slider__slide {
	img {
		width: 100vw;
	}

	.video-placeholder {
			position: absolute;
			z-index: 6;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: no-repeat center center;
			background-size: cover;
		}
}




