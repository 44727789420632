.cookie-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20000;
	padding: 30px 0 33px;
	background-color: get-color('gold');
	display: none;
	
	&__inner {
		max-width: 1260px;
		margin: 0 auto;
		
		.cell {
			position: relative;
			
			@include breakpoint(small only) {
				padding: 0 20px;
			}
		}
		
		p {
			color: get-color('dark-grey');
			margin: 0 160px 0 0;
			
			@include breakpoint(small only) {
				margin: 0 0 60px;
			}
			
			a {
				color: inherit;
				text-decoration: underline;
				transition: color 0.3s ease-in-out;
				
				&:hover {
					color: get-color('grey');
				}
			}
		}
	}
	
	&__accept {
		position: absolute;
		top: 0;
		right: 0;
		width: 140px;
		height: 35px;
		text-transform: uppercase;
		font-family: brandon-grotesque;
		font-weight: 700;
		font-size: rem-calc(13);
		line-height: rem-calc(19);
		letter-spacing: 2px;
		border: none;
		box-shadow: none;
		color: get-color('gold');
		background-color: #fff;
		text-decoration: none;
		padding: 5px 30px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;

		&:hover, &:focus {
			color: #fff;
			background-color: get-color('bronze');
		}

		@include breakpoint(small only) {
			top: initial;
			right: initial;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			
		}
	}
}

#CookieBotWidget
{
	bottom:50px!important
}

.CookiebotWidget-logo{
	position: relative;
}