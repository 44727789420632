main {
	.wrap {
		width: 100%;
		max-width: 1260px;
		margin-left: auto;
		margin-right: auto;
		
		&--narrow {
			max-width: 900px;
		}
		
		&--extra-narrow {
			max-width: 600px;
		}
		
		&:first-child {
			margin-top: 0px;
		}
	}
}

body.offer-details {

	main {
		background: url('img/gleddoch-pattern-gold.svg')
	}
}