.gallery {
	margin-bottom: 50px;
	
	&__grid {}
	
	&__item {
		width: 33%;
		float: left;
		padding: 0 12.5px 15px;
		
		@include breakpoint(medium only) {
			width: 50%;
		}
		
		@include breakpoint(small only) {
			width: 100%;
		}
		
		&.no-transition {
			transition-duration: 0s !important;
		}
		
		&-sizer {
			width: 33%;
			
			@include breakpoint(medium only) {
				width: 50%;
			}
			
			@include breakpoint(small only) {
				width: 100%;
			}
		}
		
		a {
			position: relative;
			display: block;
			
			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				top: 10px;
				right: 10px;
				width: 16px;
				height: 16px;
				background: url('img/magnifying-glass-white.svg') no-repeat center center;
				background-size: 16px 16px;
			}

			img {
				width: 100%;
			}

			p {
				color: get-color('gold');
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 1px;
				font-size: rem-calc(14);
				margin-bottom: 25px;
			}
		}
	}
	
	&__modal {
		top: 0 !important;
		padding: 100px;
		background-color: rgba(0,0,0,0.9);

		@include breakpoint( medium down) {
			padding: 25px;
		}

		&-close {
			position: absolute;
			z-index: 10;
			top: 25px;
			right: 25px;
			width: 30px;
			height: 30px;
			text-indent: -12345px;
			cursor: pointer;
			background: url('img/gleddoch-close-white.svg') center no-repeat;
			background-size: 30px;

			@include breakpoint(medium down) {
				top: 10px;
				right: 10px;
			}
		}
		
		&-image {
			text-align: center;
			position: relative;

			img {
				width: 100%;
			}
		}
		
		&-prev,
		&-next {
			position: absolute;
			bottom: 50px;
			width: 42px;
			height: 8px;
			text-indent: -12345px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			@include breakpoint(medium down) {
				bottom: 25px;
			}
		}
		
		&-prev {
			right: 94px;
			background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
			background-position: left 10px center;
			
			&:hover {
				background-position: center;
			}
		}
		
		&-next {
			right: 25px;
			background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
			background-position: right 10px center;
			
			&:hover {
				background-position: center;
			}
		}
		
		&-caption {
			color: get-color('gold');
			text-transform: uppercase;
			font-family: $body-font;
			font-size: rem-calc(14);
			line-height: rem-calc(55);
			font-weight: 500;
			letter-spacing: 1px;

			@include breakpoint(small only) {
				text-align: center;
				margin-bottom: 15px;
			}
		}
		
		&-cta {
			text-align: center;
			color: #fff;
			text-transform: uppercase;
			font-family: $body-font;
			font-size: rem-calc(14);
			line-height: rem-calc(55);
			font-weight: 500;
			letter-spacing: 1px;
			padding-right: 15px;
			
			@include breakpoint(medium) {
				text-align: right;
			}
			
			.button {
				margin-left: 25px;
				margin-bottom: 0;
				text-transform: lowercase;
				color: #fff;

				&:hover, &:focus {
					color: get-color('bronze');
				}
			}
		}
		
		&-iframe {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;
			
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
	}
}