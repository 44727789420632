.button {
	font-family: $script-font;
	font-size: rem-calc(35);
	line-height: rem-calc(55);
	color: get-color('gold');
	padding: 0;
	border: none;
	background: none;
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 2px;
	transition: color 300ms ease-in;
	text-transform: lowercase;

	&:hover, &:focus {
		background-color: transparent;
		color: get-color('bronze');
	}

	&::before {
		background-color: get-color('light-beige');
		position: absolute;
		content: '';
		bottom: 15px;
		left: 0;
		width: 100px;
		height: 2px;
		overflow: hidden;
	}

	&::after {
		background-color: get-color('bronze');
		position: absolute;
		content: '';
		bottom: 15px;
		left: -100%;
		width: 100%;
		height: 2px;
		overflow: hidden;
		transition: all 300ms ease-in;
	}

	&:hover::after {
		left: 0;
	}

	

	&--gold-bg {
		color: #fff;

		&:hover {
			color: get-color('bronze');
		}
	}

	&--grey-bg {
		color: #fff;

		&:hover {
			color: get-color('dark-grey');
		}
	}

	&--black-bg {
		color: #fff;

		&:hover {
			color: get-color('bronze');
		}
	}
}

.multiple-buttons {
	text-align: left;
	width: 100%;
	
	.button {
		margin: 0 25px;

		&:first-of-type {
			@include breakpoint(large) {
				margin-left: 0;
			}

			/*@include breakpoint(medium down) {
				margin-right: 25px;
			}*/
			
			&:last-child {
				
			}
		}
		
		&:last-child {
			@include breakpoint(large) {
				margin-right: 0;
			}
		}
	}

	.button-two {

		a.button {
			margin-right: 0;
		}
	}
}

// Centre aligned version of 
.intro .multiple-buttons {
	.button {
		margin-left: 25px;
		margin-right: 25px;
		
		&::before {
			left: 50%;
			transform: translateX(-50%);
		}
		
		&::after {
			left: 50%;
			transform: translateX(-50%);
			width: 0;
		}
		
		&:hover::after {
			width: 100%;
		}
	}
}