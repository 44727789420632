.landing {
	margin-bottom: 90px;
	
	&__item {
		margin-bottom: 30px;
	}
	
	&__slider {
		position: relative;
		min-height: 300px;
		
		&-inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;	
		}
		
		&-image {
			background: no-repeat center center;
			background-size: cover;
			
			a {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
		
		.reform-slider-arrows {
			margin: 0;
			list-style: none;
			
			.reform-prev,
			.reform-next {
				position: absolute;
				top: 50%;
				z-index: 1000;
				text-indent: -123456px;
				width: 20px;
				height: 20px;
			}
			
			.reform-prev {
				left: 20px;
				@include arrow("left", #fff, #fff)
			}
			
			.reform-next {
				right: 20px;
				@include arrow("right", #fff, #fff)
			}
		}
	}
	
	&__content {
		padding: 5%;
		text-align: left;
	}
	
	&--large .landing {
		&__item {
			position: relative;
		}
		
		&__slider {
			@include breakpoint(medium) {
				min-height: 75vh;
			}
		}
		
		&__content {
			@include breakpoint(medium) {
				position: absolute;
				z-index: 10;
				top: 20px;
				left: 20px;
				bottom: 20px;
				width: 60%;
			}
			
			@include breakpoint(large) {
				width: 40%;
			}
			
			@at-root .landing--large .landing__item:nth-child(odd) .landing__content {
				@include breakpoint(medium) {
					left: auto;
					right: 20px;
				}
			}
			
			&-inner {
				background: $body-background;
				padding: 20px;
			}
		}
	}
}