.blog-landing {
	margin-bottom: 0px;
	padding: 0 50px;

	@include breakpoint(small only) {
		padding: 0 25px;
	}

	&__featured-item {
		margin-bottom: 50px;

		@include breakpoint(small only) {
			margin-bottom: 25px;
		}

		&-image {
			min-height: 350px;
			background: no-repeat center center;
			background-size: cover;
		}

		&-content {
			padding: 75px;
			background-color: get-color('beige');

			@include breakpoint(small only) {
				padding: 25px;
			}

			h2 {
				margin-bottom: 30px;
				text-transform: uppercase;
				
				a {
					color: #fff;
					letter-spacing: 1px;
					line-height: rem-calc(35);
				}
			}
		}

		&-post {
			
			&-date {
				color: get-color('dark-grey');
				font-family: $body-font;
				font-weight: 500;
				font-size: rem-calc(14);
				line-height: rem-calc(30);
				letter-spacing: 1px;
				text-transform: uppercase;
				margin-bottom: 15px;
			}

			&-categories {
				margin: 0;
				list-style: none;
				margin-bottom: 20px;

				li {
					color: #fff;
					display: inline-block;

					&::after {
						content: ', ';
						color: #fff;
					} 
					
					&:last-child::after {
						content: '';
					}

					a {
						color: #fff;
						font-family: $body-font;
						font-weight: 500;
						font-size: rem-calc(14);
						line-height: rem-calc(30);
						letter-spacing: 1px;
						text-transform: uppercase;
					}
				}
			}
		}

		&-cta {

			.button {
				color: #fff;
				text-transform: lowercase;
			}
		}
	}
	
	&__item {
		margin-bottom: 25px;
	}
	
	&__image {
		position: relative;
		min-height: 350px;
		background: no-repeat center center;
		background-size: cover;
		margin-bottom: 50px;
		
		a {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	&__content {

		h2 {
			margin-bottom: 25px;
			text-transform: uppercase;

			a {
				color: get-color('gold');
				letter-spacing: 1px;
				line-height: rem-calc(35);
			}
		}
	}

	&__post-date {
		color: get-color('dark-grey');
		font-family: $body-font;
		font-weight: 500;
		font-size: rem-calc(14);
		line-height: rem-calc(30);
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	
	&__post-categories {
		margin-left: 0;
		list-style-type: none;
		margin-bottom: 25px;
		
		li {
			display: inline;
			
			&::after {
				content: ', ';
				color: get-color('gold');
			} 
			
			&:last-child::after {
				content: '';
			}

			a {
				color: get-color('gold');
				font-family: $body-font;
				font-weight: 500;
				font-size: rem-calc(14);
				line-height: rem-calc(30);
				letter-spacing: 1px;
				text-transform: uppercase;
			}
		} 
	}

	&__cta {

		a.button {
			text-transform: lowercase;
		}
	}
}