.testimonial {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto 50px;

    &__row {
        margin:0 auto 50px;
        padding: 50px 0px;
		background: #000 url('img/gleddoch-diamond-pattern-dark.png');
    }

    &__slider {
        color: #fff;
        width: 80%;
        margin: 0 auto;
        border: 1px solid get-color('beige');

        @include breakpoint (medium down) {
            border: none;
            width: 90%;
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            z-index: 50;
            margin-top: -12px;
            text-indent: -12345px;
            width: 42px;
            height: 8px;
            cursor: pointer;
            transform: translateY(-50%);
            transition: all 0.3s ease-in-out;

            @include breakpoint (medium down) {
                top: unset;
                transform: none;
                bottom: 0;
            }
            
            &:focus {
                outline: none;
            }
        }    

        .slick-prev {
            left: calc(0% - 52px);
			background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
			background-position: left 10px center;

            @include breakpoint (medium down) {
                left: 30%;
            }
        }

        .slick-next {
            right: calc(0% - 52px);
            background: url('img/gleddoch-arrow-right-white.svg') no-repeat;
            background-position: right 10px center;

            @include breakpoint (medium down) {
                right: 30%;
            }
        }    
    }

    &__slider-slide {
        text-align: center;
        padding: 15px 20px;
        

        &__inner {
            width: 100%;
            max-width: 650px;
            margin: 0 auto;
            padding: 20px 0;
          
            .slide-content {
                font-size: rem-calc(20);
			    line-height: 2rem;
                position: relative;


                @include breakpoint (medium down) {
                    padding-top: 30px;
                    font-size: rem-calc(16);
                }

                &::before {
                    content: '';
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top:50%;
                    transform: translateY(-50%);
                    left:-60px;
                    background-image: url('/assets/img/quatation-start.svg');
                    background-size: 40px 40px;
    
                    @include breakpoint (medium down) {
                        left: 50%;
                        top: -20px;
                        transform: translateY(0);
                        transform: translateX(-50%);
                        background-size: 30px;
                        background-repeat: no-repeat;
                        width: 30px;
                        height: 30px;
    
                    }
                }
                &::after {
                    content: '';
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top:50%;
                    transform: translateY(-50%);
                    right:-60px;
                    background-image: url('/assets/img/quatation-end.svg');
                    background-size: 40px 40px;
    
                    @include breakpoint (medium down) {
                        left: 50%;
                        top:unset;
                        bottom: -55px;
                        transform: translateY(0);
                        transform: translateX(-50%);
                        background-size: 30px;
                        background-repeat: no-repeat;
                        width: 30px;
                        height: 30px;
    
                    }
                }
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                @include breakpoint (medium down) {
                    margin-top: 80px;
                }
                p {
                    font-size: rem-calc(14);
                    color: get-color('beige');
                    text-transform: uppercase;
                    font-weight: 500;
                    margin:0 15px;
                    padding: 0; 
                    letter-spacing: 1px; 

                }
            }


        }


    }

    &__grid {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 0 20px;
        max-width: 1160px;
        margin: 0 auto;
        
        @include breakpoint (small only) {
            flex-direction: column;
        }
    }

    &__item {
        text-align: center;
        padding: 10px 20px 30px;
        border-bottom: 1px solid get-color('gold');
        margin-bottom: 30px;
        width: 40%;
        max-width: 500px;
        @include breakpoint (small only) {
            width: 100%;

        }


        h3 {
            font-family: $body-font;
			color: get-color('gold');
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
			font-size: rem-calc(14);
			line-height: rem-calc(14);
        }

        .content {
            margin: 20px auto;
            position: relative;
            padding: 0 30px;

            @include breakpoint (small only) {
              padding:  40px 30px;
            }


            &::before {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                top:0;
                left:-25px;
                background-image: url('/assets/img/quatation-start.svg');
                background-size: 25px 25px;


                @include breakpoint (small only) {
                    left: 50%;
                    transform: translateX(-50%);
                    
                }
            }
            &::after {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                top:0;
                right:-25px;
                background-image: url('/assets/img/quatation-end.svg');
                background-size: 25px 25px;

                @include breakpoint (small only) {
                    left: 50%;
                    top: unset;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }
        }

        div {
            p {
                margin:0;
                padding: 0;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 14px;
            }
        }
    
    }

    &__cta {
        text-align: center;

        a.button {
            margin-left: 25px;
            margin-right: 25px;
            color: black;
            
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }
            
            &::after {
                left: 50%;
                transform: translateX(-50%);
                width: 0;
            }
            
            &:hover::after {
                width: 100%;
            }
        }
    }
}
