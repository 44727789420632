.filter {
	text-align: center;
	margin: 25px auto;

	&__buttons {

		span {
			font-family: $body-font;
			color: get-color('gold');
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
			font-size: rem-calc(14);
			margin-right: 25px;

			@include breakpoint(small only) {
				margin-right: 10px;
			}
		}
		
		.button {
			font-family: $body-font;
			color: get-color('gold');
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
			font-size: rem-calc(14);
			line-height: rem-calc(14);
			margin: 0 25px;

			@include breakpoint(small only) {
				margin: 0 10px;
			}

			&::after, &::before {
				content: none;
			}

			&:hover {
				color: get-color('dark-grey');
			}
		}
	}
	
	span,
	.offers__filters-buttons {
		display: inline-block;
	}
	
	a,
	button {
		text-transform: capitalize;
		margin: 0px 10px;
		
		&.on {
			color: get-color('dark-grey');
		}
	}
}


.testimonial .filter .filter__buttons {
   
}

.filter {
	@include breakpoint (small only) {
        z-index: 300;
        flex-direction: column;
    }

	p {
		font-family: $body-font;
		color: get-color('gold');
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 1px;
		font-size: rem-calc(14);
		line-height: rem-calc(14);
	}

	&__buttons {
		max-height: unset;
		opacity: 1;

		@include breakpoint (small only) {
			span {
				display: none;
			}
            align-items: center;
            justify-content: center;
            flex-direction: column;
			flex-wrap: nowrap;
            border-top: none;
            width: 200px;
            padding: 10px;
			margin:0 auto;
			max-height: 0;
			transition: max-height 0.3s ease-out;
			overflow: hidden;
			opacity: 0;
			text-align: center;

            // .cls-filter {
            //     width: 20px;
            //     height: 20px;
            //     background: url('img/close-thin-gold.svg') no-repeat center center;
            //     background-size: 15px 15px;
            //     position: absolute;
            //     top: 10px;
            //     right: 10px;
            // }

            button {
				display: block;
				text-align: center;
				margin:0 auto 10px !important;

                &.on {
                    display: none;
                }
            }
        }
	}

	.mobile-toggle {
		font-family: $body-font;
		color: #000;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 1px;
		font-size: rem-calc(14);
		line-height: rem-calc(14);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        width: 200px;
        padding: 15px;
        background: url('/assets/img/circle-arrow-down.svg') no-repeat 90% center;
        padding-right: 15px;
        border: 1px solid #fff;
		background-size: 15px 15px;

        &.active {
			width: 200px;
			padding: 15px;
			padding-right: 15px;
			border: 1px solid #fff;
			background: url('/assets/img/circle-arrow-up.svg') no-repeat 90% center;
			background-size: 15px 15px;
        }
    }
}