.popup {

	position: absolute;
	left: 0px;
	bottom: -900px;
	top: auto !important;
	// transform: translateY(900px);
	transition: 1s ease;
	display: block !important;
	opacity: 0;

	@at-root .reveal-overlay {
		z-index: 60000;
		// background-color: rgba(0,0,0,0.75);
		background-color: rgba(355, 355, 355, 0.8);
	}
	
	&.reveal {
		max-width: 700px;
		border: 0;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-right: 30px;
		padding-left: 30px;
		overflow: visible;
		// background: url('img/gleddoch-pattern-gold.svg');
		background-color: #000;
		border: 1px solid #000;
		
		@include breakpoint(small only) {
			padding: 75px 25px;
			width: 100%;
			min-height: 100vh;
			overflow: auto;
		}
		
		&:focus {
			outline: none;
		}
	}
	
	&__close {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 30px;
		height: 30px;
		background: url('img/gleddoch-close-gold.svg') no-repeat center center;
		background-size: 30px 30px;
		text-indent: -12345px;
		cursor: pointer;
		
		@include breakpoint(small only) {
			top: 30px;
			right: 30px;
		}
	}
	
	&__text {
		// text-align: center;
		
		
		img {
			width: 100%;

			@include breakpoint(small) {
				margin-bottom: 2rem;
			}

			@include breakpoint(medium) {
				margin-bottom: 0px;
			}
		}

		h2 {
			font-size: 2rem;
			line-height: 2rem;
			letter-spacing: 1px;
			color: #fff;
			text-transform: uppercase;
			padding: 0 25px;
			max-width: 680px;
			width: 100%;
			margin: 0 auto 20px;
			color: #BEA57B;
		}

		p {
			color: #fff;
			padding: 0 25px;
			max-width: 680px;
			width: 100%;
			margin: 0 auto 20px;
		}

		.multiple-buttons {
			padding: 0 25px;

			a.button {
				color: #BEA57B;
			}
		}
	}
}