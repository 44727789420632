.intro {
	text-align: center;
	padding: 75px 50px;
	margin-bottom: 0px;
	background: url('img/gleddoch-diamond-pattern-light.png') center bottom;
	color: #fff;

	@include breakpoint(small only) {
		padding: 50px 25px;
		text-align: left;
	}

	&__wrap {
		
	}

	h1 {
		margin: 0 auto 50px;
		text-transform: uppercase;
	}

	h2 {
		width: 100%;
		margin: 0 auto 25px;
	}

	p {
		width: 100%;
		margin: 0 auto 25px;
		
		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	.multiple-buttons {
		@include breakpoint(medium) {
			text-align: center;
		}

		a.button {
			color: #fff;

			&:hover, &:focus {
				color: get-color('bronze');
			}
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

body.offer-details {

	.intro {
		background: transparent;
		padding-bottom: 0;
	}
}

.christmas .intro {
	background: url('img/baubles.png');
}