.key-offer {
	padding: 40px;
	background: url('img/gleddoch-diamond-pattern-dark.png');
	margin-bottom: 14px;

	@include breakpoint(small only) {
		padding: 25px;
	}

	&__inner {
		position: relative;
	}
	
	&__image {
		position: relative;

		@include breakpoint(small only) {
			margin-bottom: 25px;
		}
		
		&-inner {

			img {

				width: 100%;
				height: auto;
			}

			&::before {
				content: '';
				width: 100%;
				height: 100%;
				background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
				position: absolute;

				@include breakpoint(small only) {
					content: none;
				}
			}
		}
	}
	
	&__content {
		position: absolute;
		max-width: 360px;
		width: 100%;
		top: 50%;
		left: 100px;
		transform: translateY(-50%);
		color: #fff;
		z-index: 1;
		padding: 0;

		@include breakpoint(small only) {
			position: initial;
			top: auto;
			transform: translateY(0);
		}

		h3 {
			margin-bottom: 15px;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
		}

		h2 {
			margin-bottom: 50px;
			text-transform: uppercase;

			a {
				color: #fff;
				letter-spacing: 1px;
				@include breakpoint(large) {
					font-size: rem-calc(50);
					line-height: rem-calc(50);
				}
				@include breakpoint(small only) {
					font-size: rem-calc(35);
				}
			}
		}

		p {
			margin-bottom: 25px;
		}

		.multiple-buttons {

			a.button {
				color: #fff;

				&:hover, &:focus {
					color: get-color('bronze');
				}
			}
		}

		@include breakpoint(small only) {
			position: initial;
			top: 50px;
			left: 0;
		}
		
		.multiple-buttons {
			@include breakpoint(medium) {
				text-align: left;
			}
			
			.button:first-child {
				@include breakpoint(medium) {
					margin-left: 0;
				}
			}
		}
	}
}