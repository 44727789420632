.offers {
	margin-bottom: 50px;
	
	@include breakpoint(medium) {
		padding: 0 25px;
	}
	
	&__item {
		padding: 25px 12.5px 50px;
		text-align: left;		
		
		&-image {
			margin-bottom: 25px;
	
			img {
				width: 100%;
				display: block;
			}
		}
		
		&-content {
	
			h2 {
				text-transform: uppercase;
				margin-bottom: 37px;
	
				a {
					color: get-color('gold');
					letter-spacing: 1px;
				}
			}

			p {
				margin-bottom: 25px;
			}
		}
	}

	.key-offer {
		@include breakpoint(medium) {
			margin-left: -25px;
			margin-right: -24px;
		}
	}
}