.contact {
	width: 100%;
	margin: 0 auto;
	padding: 50px 25px;

	&__details {

		@include breakpoint(small only) {
			margin-bottom: 25px;
		}
	}

	
	&__form {

		form {
			text-align: right;
		}

		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="file"],
		textarea, select {
			display: block;
			width: 100%;
			margin-bottom: 10px;
			border: none;
			box-shadow: none;
			padding-left: 12px;
			font-family: $body-font;
			font-size: rem-calc(15);
			line-height: rem-calc(22);
			
			&::placeholder {
				color: get-color('dark-grey');
				padding-left: 12px;
			}
		}

		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="file"], select {
			border-bottom: 3px solid get-color('light-grey');
		}
		
		textarea {
			margin-top: 35px;
			height: 200px;
			border: 3px solid get-color('light-grey');

			&::placeholder {
				padding-top: 10px;
			}
		}

		.button {
			text-transform: lowercase;
		}
		
		input[type="file"] {
			border: none!important;
			padding: 0 0 0 0;
		}

		.form__checkbox {
			margin-top: 20px;
		}


		.form__email-selector {
		

			select {
				font-family: $body-font;
				font-size: rem-calc(15);
				line-height: rem-calc(22);
				padding-left: 20px;
				
			}
		}
	}
}

.cv-button {
	padding: 10px 0 10px 0!important;
}

.cv-label {
	font-family: $body-font;
	font-size: rem-calc(15);
	line-height: rem-calc(22);
	color: get-color('dark-grey');
	display: block;
	padding: 10px 0 10px 0!important;
	text-align: left;
	margin: 0 0 0 0!important;
}

