.pagination {
	text-align: center;
	padding: 0 50px;

	@include breakpoint(small only) {
		padding: 0 25px;
	}

	li {

		a {
			color: get-color('gold');
		}

		&.current {
			background-color: get-color('mid-grey');
		}
	}

	&__list {

		.pagination {

			&-previous {

				&::before {
					content: '';
					background: url('img/gleddoch-arrow-left-gold.svg') no-repeat;
					background-position: left 10px center;
					width: 42px;
					height: 8px;
					transition: all 0.3s ease-in-out;
				}
				
				&:hover::before {
					background-position: center;
				}

				a {
					font-family: $body-font;
					color: get-color('gold');
					font-size: rem-calc(14);
					line-height: rem-calc(30);
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;
				}

				&.disabled {
					color: get-color('light-grey');
					font-family: $body-font;
					font-size: rem-calc(14);
					line-height: rem-calc(30);
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;

					&::before {
						background-image: url('img/gleddoch-arrow-left-white.svg');
					}
				}
			}

			&-next {

				a {
					font-family: $body-font;
					color: get-color('gold');
					font-size: rem-calc(14);
					line-height: rem-calc(30);
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;

					&::after {
						content: '';
						background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
						background-position: right 10px center;
						width: 42px;
						height: 8px;
						transition: all 0.3s ease-in-out;
					}
					
					&:hover::after {
						background-position: center;
					}
				}

				&.disabled {
					color: get-color('light-grey');
					font-family: $body-font;
					font-size: rem-calc(14);
					line-height: rem-calc(30);
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;

					&::after {
						background-image: url('img/gleddoch-arrow-right-white.svg');
					}
				}
			}
		}
	}

	&__numbers {

		li {

			a {
				border-right: 1px solid get-color('gold');
			}

			&:last-of-type {

				a {
					border-right: none;
				}
			}
		}
	}
}