.featured-carousel {
	position: relative;
	padding: 50px;
	margin-bottom: 0px;
	background-color: rgba(get-color('gold'),0.7);

	&__wrap {
		max-width: 1100px;
		width: 100%;
		margin: 0 auto;

		@include breakpoint(medium down) {
			max-width: 640px;
		}
	}

	h2 {
		color: #fff;
		text-align: center;
		font-family: $script-font;
		font-size: rem-calc(90);
		line-height: rem-calc(55);
		font-weight: 400;
		margin: 0 auto 50px;
		
		@include breakpoint(medium down) {
			font-size: rem-calc(45);
			line-height: 1;
		}
	}
	
	&__primary-image,
	&__secondary-image {
		position: relative;
		
		a {
			position: absolute;
			z-index: 10;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	
	&--single-image {

		@include breakpoint(medium only) {
			padding: 75px 50px 25px;
		}
		
		@include breakpoint(small only) {
			padding: 75px 25px 25px;
		}

		.featured-carousel {

			&__inner {

				@include breakpoint(medium down) {
					padding-bottom: 50px;
				}
			}

			&__item {
				text-align: center;
				transition: all 0.3s ease-in-out;
				padding: 0;
				margin-top: 40px;
			}
			
			&__primary-image {
				width: 100%;
				padding-bottom: 100%;
				background: no-repeat center center;
				background-size: cover;
				transition: all 0.3s ease-in-out;
				margin: 0 auto;
			}

			&__primary-content {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(0,0,0,0.5);

				h3 {
					color: #fff;
					font-size: rem-calc(30);
					line-height: rem-calc(30);
					font-family: $heading-font;
					text-transform: uppercase;
					letter-spacing: 1px;
					margin-bottom: 25px;
					padding: 0 25px;
				}

				p {
					color: #fff;
					font-size: rem-calc(14);
					text-transform: uppercase;
					font-weight: 600;
					padding: 0 25px;
					margin-bottom: 0px;
					letter-spacing: 2px;
				}
			}
			
			&__content {
				display: none;
				overflow: hidden;
				transition: all 0.3s ease-in-out;
				margin-top: 15px;

				.multiple-buttons {
					text-align: center;

					a {
						color: #fff;

						&:hover, &:focus {
							color: get-color('bronze');
						}
					}
				}

				h3 {
					margin-bottom: 0;

					a {
						color: #fff;
						font-size: rem-calc(27);
						line-height: rem-calc(36);
						font-family: $heading-font;
						text-transform: uppercase;
						font-weight: 500;
						letter-spacing: 1px;
					}
				}

				p {
					color: get-color('dark-grey');
					margin-bottom: 15px;
					font-size: 14px;
					line-height: 24px;

					@include breakpoint(medium down) {
						padding: 0 25px;
					}
				}


			}

			&__price {

				p {
					color: #fff;
					font-size: rem-calc(14);
					text-transform: uppercase;
					font-weight: 600;
					margin-bottom: 8px;
					letter-spacing: 2px;
				}
			}
		}

		.slick-slide {
			margin: 0 25px;

			@include breakpoint(large) {
				margin: 0 32px;
			}

			&.slick-current {
				
				.featured-carousel__item {
					padding: 0;
					transform: scale(1.25);
					margin-top: 75px;
				}
				
				.featured-carousel__primary-image {
					// padding-bottom: 72%;
					margin: 0 auto;
				}
				
				.featured-carousel__primary-content {
					display: none;
				}
				
				.featured-carousel__content {
					display: block;
					padding-bottom: 50px;
					transform: scale(0.9);
					
					@include breakpoint(medium only) {
						padding-bottom: 100px;
					}

					@include breakpoint(small only) {
						padding-bottom: 75px;
					}
				}
				
				
				.featured-carousel__content h3 a {
					font-weight: 600;
				}
			}
		}
	}
	
	.slick-dots {
		display: none !important;
	}
	
	.slick-arrow {
		position: absolute;
		top: 200px;
		z-index: 50;
		margin-top: -12px;
		text-indent: -12345px;
		width: 42px;
		height: 8px;
		cursor: pointer;
		transform: translateY(-50%);
		transition: all 0.3s ease-in-out;
		
		&:focus {
			outline: none;
		}
		
		&.slick-prev {
			left: -32px;
			background: url('img/gleddoch-arrow-left-black.svg') no-repeat;
			background-position: left 10px center;

			@include breakpoint(medium down) {
				left: calc(50% - 57px);
				top: auto;
				bottom: 10px;
			}
			
			&:hover {
				background-position: center;
			}
		}
		
		&.slick-next {
			right: -32px;
			background: url('img/gleddoch-arrow-right-black.svg') no-repeat;
			background-position: right 10px center;

			@include breakpoint(medium down) {
				right: calc(50% - 57px);
				top: auto;
				bottom: 10px;
			}
			
			&:hover {
				background-position: center;
			}
		}
	}
	
	&__scrollbar {
		width: 100%;
		height: 4px;
		background-color: get-color(secondary);
		
		&-thumb {
			width: 50px;
			height: 4px;
			background-color: get-color(primary);
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			
			&:hover {
				transform: scaleY(2);
			}
		}
	}
}