.footer {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	background: url('img/gleddoch-diamond-pattern-dark.png');
	padding-bottom: 25px;

	
	&__newsletter {
		color: #fff;
		background-color: get-color('mid-grey');
		padding: 40px 25px;
		
		&-wrap {
			width: 100%;
			margin: 0 auto;
		}

		input[type="text"],
		input[type="email"] {
			width: 100%;
			padding: 15px 0 5px;
			background-color: transparent;
			border-radius: 0;
			border: solid #aeaeae;
			border-width: 0 0 2px 0;
			box-shadow: none;
			margin-bottom: 10px;
			color: #fff;
			font-family: $body-font;
			font-size: rem-calc(15);
			line-height: rem-calc(22);

			&::placeholder {
				color: #fff;
				opacity: 1;
			}
			
			&:focus {
				@include disable-mouse-outline;
			}
		}

		h2 {
			text-transform: uppercase;
			padding: 0 25px;
			margin-bottom: 20px;
		}

		button[type="submit"],
		input[type="submit"] {
			font-family: $script-font;
			background: none;
			border: none;
			box-shadow: none;
			font-size: rem-calc(35);
			line-height: rem-calc(35);
			cursor: pointer;
			color: #fff;
			transition: all 300ms ease-in;
			padding: 2px 0 3px;

			&:hover, &:focus {
				background-color: transparent;
				color: get-color('dark-grey');
			}
		}

		&-check {

			@include breakpoint(small only) {
				margin-bottom: 25px;
			}
		}

		input[type="checkbox"] {
			position: absolute; 
			opacity: 0;
			
			~ label,
			+ label {
				position: relative;
				padding-left: 25px;
				font-size: rem-calc(12);
				color: #fff;
				cursor: pointer; 
				
				&::before,
				&::after {
					content: '';
					position: absolute; 
				}
				
				&::before {
					top: 0;
					left: 0;
					width: 12px;
					height: 12px;
					background: #fff;
				}
				
				&::after {
					top: 1px;
					left: 1px;
					width: 10px;
					height: 10px;
					content: '';
					opacity: 0;
					background: get-color('gold');
					transition: opacity 0.3s ease-in-out;
				}

				a {
					color: #fff;
					text-decoration: underline;
					transition: color 300ms ease-in;

					&:hover, &:focus {
						color: get-color('bronze');
					}
				}
			}
			
			&:checked ~ label,
			&:checked + label {
				&:after {
					opacity: 1;  
				}
			}
		}

		p {
			font-family: $body-font;
			font-size: rem-calc(16);
			line-height: rem-calc(26);
			padding: 0 25px;
		}

		.cell.small-12.text-left {

			p {
				padding: 0px;
			}
		}

		&-submit {

			@include breakpoint(medium down) {
				text-align: center;
			}

			& > div {
				display: inline-block;
				position: relative;
				overflow: hidden;
				transition: color 300ms ease-in;

				&::before {
					background-color: #aeaeae;
					position: absolute;
					content: '';
					bottom: 0px;
					left: 0;
					width: 100px;
					height: 2px;
					overflow: hidden;
				}
			
				&::after {
					background-color: get-color('bronze');
					position: absolute;
					content: '';
					bottom: 0px;
					left: -100%;
					width: 100%;
					height: 2px;
					overflow: hidden;
					transition: all 300ms ease-in;
				}
			
				&:hover::after {
					left: 0;
					background-color: get-color('grey');
				}
			}
		}
	}
    
    &__logo {
	    padding: 25px;
	    
	    @include breakpoint(medium down) {
		    padding: 5px;
	    }
	    
	    img {
		    width: 100%;
	    }
    }
    
    &__social {
		text-align: end;

		@include breakpoint(medium down) {
			margin-bottom: 25px;
		}

		@include breakpoint(small only) {
			text-align: left;
		}

		&-wrap {
			//margin: 25px 0;
			text-align: left;
			
			@include breakpoint(large) {
				float: right;
			}

			p {
				color: get-color('gold');
				text-transform: uppercase;
				margin-bottom: 5px;
				padding-left: 5px;
			}

			ul {
				list-style-type: none;
				margin: 0;
			}
			
			li {
				display: inline-block;
				margin: 0 5px;
			}
			
			img,
			svg {
				width: auto;
				height: 16px;
				fill: #fff;
			}
		}
	}
	
	&__nav {
		width: 100%;
		margin: 0 auto 25px;
		padding-top: 50px;

		@include breakpoint(medium down) {
			padding: 25px;
			margin-bottom: 0;
		}
	}
    
    &__primary,
    &__secondary {
	    ul {
		    margin: 0 auto;
		    list-style-type: none;
	    }
	    
	    li {
		    display: block;
		    margin: 0 5px;
			white-space: nowrap;
			line-height: 12px;
			text-align: left;
			
			a {
				font-size: rem-calc(10);
				line-height: rem-calc(10);
				font-weight: 500;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #fff;
			}
	    }
    }

	&__primary {

		ul {

			li {
				margin: 0;
			}
		}
	}
    
    &__secondary {
		font-size: 80%;
		color: #fff;
		text-transform: uppercase;
		text-align: left;
		font-size: rem-calc(10);
		letter-spacing: 2px;
		font-weight: 500;

		&-address {

			@include breakpoint(medium down) {
				margin-bottom: 25px;
			}

			p {
				margin-bottom: 0;
				line-height: 1.5;

				&:first-of-type {
					color: get-color('gold');
				}
				
				a {
					color: inherit;
				}
			}
		}

		&-contact {

			@include breakpoint(medium down) {
				margin-bottom: 25px;
			}

			p {
				margin-bottom: 0;
				line-height: 1.5;
				
				a {
					color: inherit;
				}
			}
		}
    }
	
	&__tertiary {

		margin: 0 auto;
		padding-top: 50px;

		@include breakpoint(small only) {
			padding-top: 0;
		}
	}

    &__copyright {
		align-self: center;

		@include breakpoint(medium down) {
			margin-bottom: 25px;
		}
		
		p {
			color: get-color('bronze');
			text-transform: uppercase;
			margin: 0;
		}
	}
	
	&__site-by {
		text-align: end;
		align-self: center;

		@include breakpoint(medium down) {
			margin-bottom: 25px;
			text-align: left;
		}

		@include breakpoint(small only) {
		}

		p {
			margin: 0;
			padding-left: 5px;
			
			a {
				color: get-color('bronze');
				text-transform: uppercase;
				vertical-align: top;
			}
		}
	}

	&__logo {
		margin: 0;
		padding: 0;
		align-self: center;

		@include breakpoint(medium down) {
			margin: 10px 0 25px 0;
		}

		div {
			max-width: 120px;
			margin: 0 auto;

			@include breakpoint(medium down) {
			float: left;	
			}
		}
	}

	#hide-copyright {

		@include breakpoint(large up) {
			display: none !important;
		}
		
	}


}

