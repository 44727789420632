.accommodation {

	&__wrap {
		max-width: 1260px;
		width: 100%;
		margin: 0 auto;
	}
	
	&__views {
		max-width: 1260px;
		width: 100%;
		text-align: right;
		margin: -55px auto 25px;
		padding: 0 25px;
		color: #fff;

		@include breakpoint(medium down) {
			display: none;
		}
		
		span {
			margin-right: 15px;
			text-transform: uppercase;
			font-family: $body-font;
			font-size: rem-calc(10);
			line-height: rem-calc(15);
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 2px;
		}
		
		ul {
			display: inline;
			margin: 0;
			list-style-type: none; 
			
			li {
				display: inline; 
			}
		}
		
		&-list-button,
		&-grid-button {
			text-indent: -12345px;
			height: 15px;
			cursor: pointer;
			background: no-repeat center center;
			background-size: contain;
			opacity: 0.5;
			transition: opacity 0.3s ease-in-out; 
			
			&:hover,
			&:active,
			&.current {
				opacity: 1;
			}
		}
		
		&-list-button {
			width: 19px; 
			margin-right: 15px;
			background-image: url('img/gleddoch-view-list-white.svg');
		}
		
		&-grid-button {
			width: 15px;
			background-image: url('img/gleddoch-view-grid-white.svg');
		}
	}

	&__items {
		display: none;

		&.active {
			display: block;
		}
	}

	&__grid {

		&-row {
			padding: 50px 0;

			@include breakpoint(medium down) {
				padding: 50px 25px;
			}

			&.pattern-dark-bg {
				background: url('img/gleddoch-diamond-pattern-dark.png') center top;
			}

			&.pattern-gold-bg {
				background: url('img/gleddoch-pattern-gold.svg');
			}

			&.beige-bg {
				background-color: get-color('beige');
			}

			&.residences {

				@include breakpoint(medium down) {
					padding: 0;
				}

				.accommodation__grid {

					&-image {

						.slick-prev {
							left: auto;
							right: calc(8% + 57px);
							background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
							background-position: left 10px center;
							
							&:hover {
								background-position: center;
							}
						}
						
						.slick-next {
							left: auto;
							right: 8%;
							background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
							background-position: right 10px center;
							
							&:hover {
								background-position: center;
							}
						}

						&-slide {

							&-overlay {
								background: rgba(#000, 0.5);

								@include breakpoint(medium down) {
									background: transparent;
								}
							}
						}
					}

					&-info {

						@include breakpoint(medium down) {
							position: relative;
							top: 0;
							left: 0;
							transform: translateY(0);
							padding: 50px 25px;

							@include breakpoint(medium down) {
								width: 100% !important;
							}
						}
					}

					&-content {

						h2 {
							max-width: 100%;
							text-align: center;

							@include breakpoint(medium down) {
								text-align: left;
							}

							a {
								font-size: rem-calc(30);
								line-height: rem-calc(35);
								
								@include breakpoint(medium down) {
									font-size: rem-calc(35);
									color: get-color('gold');
								}
							}
						}

						p {
							@include breakpoint(large) {
								text-align: center;
								margin-left: auto;
								margin-right: auto;
							}
							
							@include breakpoint(medium down) {
								color: get-color('dark-grey');
							}
						}
					}

					&-price {

						h3 {
							@include breakpoint(large) {
								text-align: center;
								margin-left: auto;
								margin-right: auto;
							}
							
							@include breakpoint(medium down) {
								color: get-color('gold');
							}
						}
					}

					&-cta {

						.multiple-buttons {
							text-align: center;

							@include breakpoint(medium down) {
								text-align: left
							}

							.button-two {

								a.button {
									margin-right: 0;
								}
							}

							a.button {

								@include breakpoint(medium down) {
									color: get-color('gold');

									&:hover, &:focus {
										color: get-color('bronze');
									}
								}
							}
						}
					}
				}
			}

			&.full-width {
				> .grid-x {
					max-width: 1260px;
					margin: auto;
					
					> .cell {
						@include breakpoint(medium) {
							margin-left: 4.1666%;
						}
					}
				}
				
				.accommodation__grid {
					
					&-info {

						@include breakpoint(medium down) {
							padding-top: 25px;
							position: relative;
							top: 0;
							left: 0;
							transform: translateY(0);
						}
					}
				}
			}

			&.half-width {
				> .grid-x {
					max-width: 1260px;
					margin: auto;
				}
				
				.accommodation__grid-item {
					@include breakpoint(medium) {
						padding: 0 50px;
					}
				}
				
				.accommodation__grid-info {
					padding: 25px 20px 0;
					
					@include breakpoint(medium) {
						padding: 0 50px;
					}
				}
			}
			
			&.residences {
				max-width: 1260px;
				margin: auto;
				padding: 75px 0;
			}
		}

		&-item {
			position: relative;

			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}
			
			.accommodation__details {
				padding-top: 0;
			}
			
			.accommodation__cta {
				
				.multiple-buttons {
					text-align: left;
					
					.button-one, .button-two {
						display: inline-block;
					}
					
					.button {
						color: #fff;
						
						&:hover, &:focus {
							color: get-color('bronze');
						}
					}
				}
			}
		}


		&-image {
			
			&-slide {
				position: relative;

				img {
					position: relative;
					top:0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					min-height: 500px;

					@include breakpoint(medium down) {
						min-height: 280px;
					}
				}
				
				&-overlay {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0) 100%);

					@include breakpoint(medium down) {
						background: transparent;
					}
				}
			}
			
			.slick-prev, .slick-next {
				position: absolute;
				bottom: 50px;
				width: 42px;
				height: 8px;
				text-indent: -12345px;
				z-index: 1;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
			}
			
			.slick-prev {
				left: 8%;
				background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
				background-position: left 10px center;

				@include breakpoint(medium down) {
					left: auto;
					right: 89px;
				}
				
				&:hover {
					background-position: center;
				}
			}
			
			.slick-next {
				left: calc(8% + 57px);
				background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
				background-position: right 10px center;

				@include breakpoint(medium down) {
					left: auto;
					right: 25px;
				}
				
				&:hover {
					background-position: center;
				}
			}
		}

		&-info {
			position: absolute;
			top: calc(50% - 29px);
			left: 8%;
			transform: translateY(-50%);
			width: 84% !important;

			@include breakpoint(medium down) {
				position: relative;
				top: 0;
				left: 0;
				transform: translateY(0);
				width: 100% !important;
			}
		}
		
		&-content {
			
			h2,p {
				text-align: left;
				max-width: 400px;
				width: 100%;
				color: #fff;
			}
			
			h2 {
				margin-bottom: 50px;

				@include breakpoint(medium down) {
					margin-bottom: 25px;
				}
				
				a {
					color: #fff;
					font-size: rem-calc(50);
					line-height: rem-calc(50);
					text-transform: uppercase;
					
					@include breakpoint(medium down) {
						font-size: rem-calc(35);
						color: get-color('gold');
					}

					@at-root .pattern-dark-bg #{&}, .pattern-gold-bg #{&}, .beige-bg #{&} {

						@include breakpoint(medium down) {
							color: #fff;
						}
					}
				}
			}
			
			p {
				margin-bottom: 25px;
				color: #fff;

				@include breakpoint(medium down) {
					color: get-color('dark-grey');
				}

				@at-root .pattern-dark-bg #{&}, .pattern-gold-bg #{&}, .beige-bg #{&} {

					@include breakpoint(medium down) {
						color: #fff;
					}
				}
			}
			
			@include breakpoint(medium down) {
				text-align: center;
			}
		}
		
		&-details {
			
			@include breakpoint(medium down) {
				text-align: center;
			}
		}
		
		&-price {
			
			h3 {
				color: #fff;
				font-size: rem-calc(14);
				line-height: rem-calc(17);
				font-weight: 500;
				text-transform: uppercase;
				text-align: left;
				max-width: 355px;
				width: 100%;
				margin-bottom: 25px;
				letter-spacing: 1px;

				@include breakpoint(medium down) {
					color: get-color('gold');
				}

				@at-root .pattern-dark-bg #{&}, .pattern-gold-bg #{&}, .beige-bg #{&} {

					@include breakpoint(medium down) {
						color: #fff;
					}
				}
			}
		}
		
		&-cta {
			
			.multiple-buttons {
				
				.button-one, .button-two {
					display: inline-block;
				}
				
				a.button {
					color: #fff;

					@include breakpoint(medium down) {
						color: get-color('gold');
					}

					&:hover, &:focus {
						color: get-color('bronze');
					}

					@at-root .pattern-dark-bg #{&}, .pattern-gold-bg #{&}, .beige-bg #{&} {

						@include breakpoint(medium down) {
							color: #fff;

							&:hover, &:focus {
								color: get-color('bronze');
							}
						}
					}
				}
			}
		}
	}

	&__list {
		padding-top: 50px;

		&-item {
			margin-bottom: 50px;

			&.beige-bg {

				background-color: get-color('beige');

				.accommodation__list-content {
		
					h2 {
		
						a {
							color: #fff;
						}
					}
		
					p {
					}
				}

				.accommodation__list-cta {

					.multiple-buttons {

						a.button {
							color: #fff;

							&:hover, &:focus {
								color: get-color('bronze');
							}
						}
					}
				}
				
				.accommodation__list-image .slick-next {
					background-image: url(img/gleddoch-arrow-right-black.svg);
				}
			}
		}

		&-image {

			&-slide {
				position: relative;

				img {
					position: relative;
					top:0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					min-height: 290px;
				}
				
				&-overlay {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}
			
			.slick-prev, .slick-next {
				position: absolute;
				bottom: 25px;
				width: 32px;
				height: 8px;
				text-indent: -12345px;
				z-index: 1;
				cursor: pointer;
			}
			
			.slick-prev {
				right: 25px;
				background: url('img/gleddoch-arrow-left-white.svg') no-repeat center;
			}
			
			.slick-next {
				right: -50px;
				background: url('img/gleddoch-arrow-right-gold.svg') no-repeat center;
			}
		}

		&-content {
			padding: 4%;

			h2 {
				margin-bottom: 50px;

				a {
					color: get-color('gold');
					text-transform: uppercase;
				}
			}

			p {
			}
		}

		&-details {
			padding: 4% 2%;
		}

		&-price {

			h3 {
				text-transform: uppercase;
				margin-bottom: 25px;
				font-weight: 500;
				letter-spacing: 1px;
			}
		}

		&-cta {

		}

		&-residences {

			&-image {

				img {
					position: relative;
					top:0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					min-height: 500px;
				}

				.slick-prev, .slick-next {
					position: absolute;
					bottom: 25px;
					width: 32px;
					height: 8px;
					text-indent: -12345px;
					z-index: 1;
					cursor: pointer;
				}
				
				.slick-prev {
					right: 25px;
					background: url('img/gleddoch-arrow-left-white.svg') no-repeat center;
				}
				
				.slick-next {
					right: -50px;
					background: url('img/gleddoch-arrow-right-black.svg') no-repeat center;
				}
			}

			&-info {
				padding: 50px;
				background-color: get-color('beige');

				@include breakpoint(medium down) {
					padding: 25px;
				}
			}

			&-content {

				h2 {
					margin-bottom: 50px;

					a {
						color: #fff;
						text-transform: uppercase;
					}
				}

				p {

				}
			}

			&-price {

				h3 {
					text-transform: uppercase;
					margin-bottom: 25px;
					font-weight: 500;
					color: #fff;
					letter-spacing: 1px;
				}
			}

			&-cta {
				
				.multiple-buttons {

					.button-one, .button-two {
						display: inline-block;
					}

					a.button {
						color: #fff;

						&:hover, &:focus {
							color: get-color('bronze');
						}
					}
				}
			}
		}
	}
}	