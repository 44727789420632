.off-canvas {
	z-index: 50000;
	width: 100%;
	transform: translateX(-100%);
	background: url('img/gleddoch-pattern-gold.svg');
	
	&.is-open {
		transform: translateX(0);
	}
	
	&__main {
		width: 100%;
		position: relative;
		z-index: 5;
	}
	
	&__wrapper {
		max-width: 1920px;
		width: 100%;
		margin: 0 auto;
		min-height: 80vh;
	}
	
	&__close {
		img {
			position: absolute;
			z-index: 10;
			top: 50px;
			left: 50px;
			width: 30px;
			height: 30px;
			text-indent: -12345px;
			cursor: pointer;
			
			@include breakpoint(small only) {
				left: 25px;
			}
		}
	}

	&__logo {
		position: absolute;
		top: 14px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
		width: 100%;
		max-width: 150px;
		margin: 0 auto;

		@include breakpoint(small only) {
			max-width: 115px;
		}
	}
	
	&__nav {
		padding: 200px 25px 0 8%;
		
		@include breakpoint(medium down) {
			padding: 90px 25px 0;
			border-bottom: 1px solid get-color('beige');
		}
	}
	
	&__nav-primary {
		margin-bottom: 50px;

		@include breakpoint(small only) {
			margin-bottom: 25px;
		}
		
		> ul {
			margin: 0;
			list-style-type: none;
			
			> li {
				margin-bottom: 27px;
				
				@include breakpoint(small only) {
					margin-bottom: 20px;
				}

				a {
					text-transform: uppercase;
					font-family: $heading-font;
					color: #fff;
					font-size: rem-calc(30);
					line-height: rem-calc(35);
				}

				&.open {

					a {
						color: get-color('dark-grey');
					}

					> ul {

						> li {
							line-height: 1;
							margin-bottom: 25px;

							a {
								color: #fff;
								font-family: $body-font;
								font-weight: 500;
								letter-spacing: 1px;
								line-height: 1;

								&:hover {
									color: get-color('dark-grey');
								}
							}
						}
					}
				}
				
				> ul {
					margin: 15px 0 0 50px;
					list-style-type: none;
					display: none;
					
					@include breakpoint(small only) {
						margin-left: 0;
					}
					
					> li {
						margin-bottom: 0px;
						
						@include breakpoint(small only) {
							line-height: 1;
							margin-bottom: 0px;
						}
						
						a {
							color: #fff;
							font-size: rem-calc(14);
							line-height: rem-calc(30);
						}
					}
				}
			}
		}
	}

	&__nav-secondary {
		padding: 25px;
		margin: 0 auto;

		ul {
			list-style: none;
			margin: 0;

			li {

				a {
					font-family: $body-font;
					color: #fff;
					text-transform: uppercase;
					font-size: rem-calc(14);
					line-height: rem-calc(35);
					font-weight: 500;
					letter-spacing: 1px;
				}
			}
		}
	}
	
	
	&__nav-header {
		text-align: center;
		margin-bottom: 50px;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			display: block !important;
		}

		@include breakpoint(medium down) {
			background-color: get-color('beige');
			padding: 25px;
			margin-bottom: 25px;
		}
		
		ul {
			margin: 0;
			list-style-type: none;
		}
		
		li {
			margin: 0 25px;
			display: inline;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}

			@include breakpoint(medium down) {
				display: block;
				text-align: left;
				margin: 0;
			}

			a {
				text-transform: uppercase;
				font-family: $body-font;
				color: #fff;
				font-weight: 500;
				font-size: rem-calc(14);
				line-height: rem-calc(30);
				letter-spacing: 2px;
			}
		}
	}
	
	
	
	&__sidebar {
		text-align: right;
		padding: 200px 8% 50px 25px;
		
		@include breakpoint(medium only) {
			padding-left: 30px;
		}
		
		&-inner {
			width: 100%;
			max-width: 370px;
			display: inline-block;
			text-align: center;

			h2,h3,p,a {
				text-align: center;
				color: #fff;
			}

			h2 {
				font-weight: 500;
				margin-bottom: 25px;
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			h3 {
				font-weight: 500;
				margin-bottom: 25px;
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			p {
				color: get-color('dark-grey');
				margin-bottom: 15px;
			}
			
			a {
				padding: 0;
				margin: 0 25px 25px;
			}
		}
	}
	
	&__offer {
		position: relative;
		text-align: center;
		margin-bottom: 25px;
		
		&-image {
			background: no-repeat center center;
			background-size: cover;
			min-height: 280px;
			margin-bottom: 24px;
		}
		
		&-inner {
			background-color: #fff;
		}
	}
	
	
	&__footer {
		width: 100%;
		background: url('img/gleddoch-diamond-pattern-dark.png');
		min-height: 20vh;

		@include breakpoint(medium down) {
			background: none;
		}
		
		&-wrapper {
			padding: 30px 0 40px;

			@include breakpoint(medium down) {
				padding: 0;
			}
		}
		
		&-info {
			max-width: 1260px;
			width: 100%;
			margin: 0 auto;

			@include breakpoint(medium down) {
				max-width: 280px;
			}
			
			p, a {
				color: #fff;
				letter-spacing: 2px;
				line-height: rem-calc(15);
				margin-bottom: 0px;
				text-transform: uppercase;
			}
		}

		&-site-by {
			max-width: 1260px;
			width: 100%;
			margin: 0 auto;
			padding-top: 25px;

			@include breakpoint(medium down) {
				padding: 25px 50px;
			}

			p {

				a {
					text-transform: uppercase;
					color: #fff;
				}
			}
		}
	}
	
	&__nav-footer-primary,
	&__nav-footer-secondary {
		color: #fff;
		width: 100%;
		text-align: center;
		
		ul {
			margin: auto;
		}
		
		li {
			display: inline;
			margin: 0 9px;
			white-space: nowrap;
		}
	}
}