.home {

	&__intro {

		&-wrap {

		}

		padding: 75px 0px;
		background: url('img/gleddoch-pattern-gold.svg');
		color: #fff;

		@include breakpoint(small only) {
			padding: 50px 25px;
		}
		
		
		
		h1 {
			text-transform: uppercase;
		}

		a {
			color: #fff;
		}

		&-content {

			@include breakpoint(small only) {
				margin-bottom: 25px;
			}
		}

		&-info {
			padding: 0 50px;

			@include breakpoint(small only) {
				padding: 0;
			}

			p {
				margin-bottom: 25px;
			}

			li {
				font-family: $body-font;
			}
		}
	}

	&__content {
		padding: 0 50px 0 8%;

		@include breakpoint(medium down) {
			padding: 50px 25px;
		}

		&-wrap {
			max-width: 350px;
			width: 100%;

		}

		h2 {
			margin-bottom: 50px;
			color: get-color('gold');
			font-size: rem-calc(35);
			line-height: rem-calc(35);
			text-transform: uppercase;

			@include breakpoint(large) {
				font-size: rem-calc(50);
				line-height: rem-calc(50);
			}

			a {
				color: inherit;
			}
		}

		h3 {
			margin-bottom: 25px;
			color: get-color('gold');
			text-transform: uppercase;
			font-weight: 500;
		}

		p {
			margin-bottom: 25px;
		}

	}

	&__row {
		//margin-bottom: -6px; // quick and dirty fix for a gap I can't track down
		
		&.beige-bg {
			background-color: get-color('beige');

			h2 {
				color: #fff;
				a {
					
				}
			}
	
			h3 {
				color: #fff;
			}
	
			p {
				
			}

			.multiple-buttons {

				a.button {
					color: #fff;

					&:hover, &:focus {
						color: get-color('bronze');
					}
				}
			}
		}

		&-slider {

			&-slide {
				position: relative;
				min-height: 560px;
			}
			
			
			
			a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: -10px;
				display: flex;
			}

			picture {
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom: 0;
			}
			
			img {
				object-fit: cover;
				width: 100%;
				height: auto;
			}

			.slick-prev,
			.slick-next {
				position: absolute;
				bottom: 50px;
				z-index: 1;
				text-indent: -12345px;
				width: 42px;
				height: 8px;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				@include breakpoint(medium down) {
					bottom: 25px;
				}
			}
			
			.slick-prev {
				left: calc(100% - 52px);
				background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
				background-position: left 10px center;

				@include breakpoint(small only) {
					bottom: 25px;
					left: calc(100% - 144px);
				}
				
				&:hover {
					background-position: center;
				}
			}
			
			.slick-next {
				left: calc(100% + 10px);
				background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
				background-position: right 10px center;
				
				@include breakpoint(small only) {
					bottom: 25px;
					left: calc(100% - 52px);
				}
				
				&:hover {
					background-position: center;
				}
			}
		}

		&--image-right {

			.home__row-slider {
				@include breakpoint(medium) {
					order: 2;
				}

				.slick-prev {
					left: calc(0% - 52px);
					background: url('img/gleddoch-arrow-left-gold.svg') no-repeat;
					background-position: left 10px center;
					
					@include breakpoint(small only) {
						background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
						background-position: left 10px center;
						bottom: 25px;
						left: calc(100% - 114px);
					}
					
					&:hover {
						background-position: center;
					}
				}
	
				.slick-next {
					left: calc(0% + 10px);
					background: url('img/gleddoch-arrow-right-white.svg') no-repeat;
					background-position: right 10px center;

					@include breakpoint(small only) {
						background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
						background-position: right 10px center;
						bottom: 25px;
						left: calc(100% - 52px);
					}
					
					&:hover {
						background-position: center;
					}
				}
			}
			
			.home__row-content {
				padding-left: 0;

				@include breakpoint(medium) {
					order: 1;
				}
			}

			.home__content {
				padding: 0 50px 0 0;

				@include breakpoint(medium down) {
					padding: 50px 25px;
				}
			}
		}

		&--wide {
			padding: 50px 0px;
			background: #000 url('img/gleddoch-diamond-pattern-dark.png');

			@include breakpoint(small only) {
				padding: 25px 25px 50px;
			}

			&-wrap {
				position: relative;

				.home__content {
					position: absolute;
					max-width: 360px;
					width: 100%;
				    top: calc(50% - 29px);
				    left: 75px;
					color: #fff;
					z-index: 1;
					padding: 0;
					transform: translateY(-50%);

					@include breakpoint(small only) {
						position: initial;
						top: 50px;
						left: 0;
						transform: translateY(0);
					}

					h2 {
						color: #fff;
						font-size: rem-calc(50);
						line-height: 1;
						letter-spacing: 1px;
						a {
							
						}
					}

					h3 {
						color: #fff;
					}

					a.button {
						color: #fff;
					}
				}
				
				.home__row-slider {
					&::before {
						@include breakpoint(medium) {
							content: '';
							position: absolute;
							z-index: 10;
							top: 0;
							left: 0;
							bottom: 0;
							width: 50%;
							background: linear-gradient(to right, rgba(25,25,25,0.9) 0%,rgba(25,25,25,0) 100%);
						}
					}

					@include breakpoint(small only) {
						margin-bottom: 50px;
					}
					
					&-slide {
						min-height: 500px;
						
						&::before {
							content: '';
							width: 100%;
							height: 100%;
							background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
							position: absolute;

							@include breakpoint(small only) {
								content: none;
							}
						}
						img {
							margin: 0 auto;
							max-height: 500px;
						}
					}

					.slick-prev,
					.slick-next {
						position: absolute;
						bottom: 50px;
						z-index: 10;
						text-indent: -12345px;
						width: 42px;
						height: 8px;
					}
					
					.slick-prev {
						left: 125px;
						background: url('img/gleddoch-arrow-left-white.svg') no-repeat;
						background-position: left 10px center;

						@include breakpoint(small only) {
							bottom: 25px;
							left: calc(100% - 114px);
						}
						
						&:hover {
							background-position: center;
						}
					}

					.slick-next {
						left: 175px;
						background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
						background-position: right 10px center;

						@include breakpoint(small only) {
							bottom: 25px;
							left: calc(100% - 52px);
						}
						
						&:hover {
							background-position: center;
						}
					}
				}
			}
		}
	}
	
	
	
	// Forcing tall image slider is tricky...
	&__row-slider {
		.slick-list,
		.slick-track,
		.slick-slide > div {
			height: 100%;
		}
	}
	
	
	&__row-slider img,
	&__row-slider-slide {
		height: 100%;
	}
	
	
	
	.vertical-center-cell {
		padding: 25px 0;
	}
}


.christmas .home__intro {
	background: url('img/baubles.png');
}
.home__row-slider-slide {
	img {
		width: 100%;
	}
}

@media screen and (max-width: 600px){
	#CookiebotWidget {
		bottom: 60px !important;
	}
}

.widget-visible {
	transition: 0.3s ease !important;

	iframe {
		transition: 0.3s ease !important;
	}
}
