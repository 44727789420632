@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
	primary: #535A52, // very dark grey/geeen
	secondary: #767676, // default
	success: #3adb76, // default
	warning: #ffae00, // default
	alert: #cc4b37, // default
	'dark-grey': #191919,
	'mid-grey': #8b8b8b,
	'grey': #676767,
	'light-grey': #c4c4c4,
	'gold': #bfa677,
	'beige': #d2c09f,
	'light-beige': #dfd2ba,
	'bronze': #887857
);

$body-background: #fff;

$body-font: 'brandon-grotesque', sans-serif;
$heading-font: 'playfair-display', serif;
$script-font: 'sacramento', serif;

