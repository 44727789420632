.directions {
	position: relative;
	
	
	&__map {
		position: relative;
		text-align: left;
		min-height: 350px;
		
		@include breakpoint(large up) {
			min-height: 525px;
		}
		
		.map-container {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	
	&__content {
		background-color: rgba(0, 0, 0, 0.3);
		padding: 20px;
		
		@include breakpoint(large up) {
			position: absolute;
			top: 0;
			right: 0;	
			width: 35%;
			text-align: left;
		}
	}
	
	&__reults {
	}
}