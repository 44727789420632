.large-image-gallery {

	&__inner {
		margin: 0 auto;
		position: relative;

		img {
			width: 80%;
			height: auto;
			margin: 0 auto;
		}
	}

	&__item {
		transition: 0.1s ease;
		text-align: center;
	}

	.slick-slide {
		opacity: 1;

		@include breakpoint (large) {
			opacity: 0.5; /* Faded side images */
			transition: opacity 0.3s ease; /* Smooth transition when moving */
		}
	}
	
	.slick-center {
		opacity: 1 !important; /* Full opacity for the center image */
	}

	
	
	.slick-prev, .slick-next {
		// background-color: #fff;
		border: none;
		color: #000;
		font-size: 24px;
		z-index: 10;
		position: absolute;
		top: 50%; 
		-webkit-transform: translate(0%,-50%);
			-ms-transform: translate(0%,-50%);
				transform: translate(0%,-50%);
	}
	
	.slick-prev {
		left: 10px !important;

		@include breakpoint (medium) {
			left: 20px !important;
		}

		@include breakpoint (large) {
			left: 80px !important;
		}
	}
	
	.slick-next {
		right: 10px !important;

		@include breakpoint (medium) {
			right: 20px !important;
		}

		@include breakpoint (large) {
			right: 80px !important;
		}
	}

}