/*
	Generates a thin arrow in the style of: < > ^ v
	The parent element requires absolute positioning
*/
@mixin arrow($direction: "right", $color: #000, $hover-color: #000) {
	&::before,
	&::after {
		content: '';
		position: absolute;
		
		background-color: $color;
		transition: all 0.3s ease-in-out;
		
		@if $direction == "left" or $direction == "right" {
			top: 50%;
			left: 0;
			right: 0;
			height: 2px;
			margin-top: -1px;
		} @else if $direction == "up" or $direction == "down" {
			left: 50%;
			top: 0;
			bottom: 0;
			width: 2px;
			margin-top: -1px;
		}
		
		@if $direction == "right" {
			transform-origin: right center;
		} @else if $direction == "left" {
			transform-origin: left center;
		} @else if $direction == "up" {
			transform-origin: center top;
		} @else if $direction == "down" {
			transform-origin: center bottom;
		}
	}
	
	&::before {
		@if $direction == "left" or $direction == "right" {
			transform: translateX(-10%) rotate(45deg);
		} @else if $direction == "up" or $direction == "down" {
			transform: translateY(-10%) rotate(45deg);
		}
	}
	
	&::after {
		@if $direction == "left" or $direction == "right" {
			transform: translateX(-10%) rotate(-45deg);
		} @else if $direction == "up" or $direction == "down" {
			transform: translateY(-10%) rotate(-45deg);
		}
	}
	
	&:hover,
	&:active {
		&::before,
		&::after {
			background-color: $hover-color;
		}
	}
}

@mixin flex-align-center {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}