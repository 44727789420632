.blog-detail {
	margin-bottom: 75px;

	.intro {
		margin-bottom: 50px;

		h1 {
			margin-bottom: 0;
		}
	}

	&__info {
		max-width: 650px;
		width: 100%;
		margin: 0 auto;
		padding: 0 25px;
	}
	
	&__content {
		p {

			img {
				width: 100% !important;
				max-width: 100% !important;
				height: auto !important;
			}
				
			a {
				font-family: $body-font;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				color: get-color('gold');
				text-decoration: underline;
				
				&:hover, &:focus {
					color: get-color('bronze');
				}
			}
		}

		ul, ol {
			
			li {
				font-family: $body-font;
				font-size: rem-calc(16);
				line-height: rem-calc(26);
				color: get-color('dark-grey');

				a {
					font-family: $body-font;
					font-size: rem-calc(16);
					line-height: rem-calc(26);
					color: get-color('gold');
					text-decoration: underline;

					&:hover, &:focus {
						color: get-color('bronze');
					}
				}
			}
		}
	}
}