.virtual-tours {

	padding-bottom: 4rem;

	&__inner {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 25px;

		@include breakpoint(large) {
			padding: 0 0px;
		}
	}

	&__header {
		text-align: center;
		color: get-color('gold');
		text-transform: uppercase;
		margin: 0 auto 50px;
	}


	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		@include breakpoint (large) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		&-item {
			img {
				width: 100%;
				margin-bottom: 0.5rem;
			}

			h3 {
				text-transform: uppercase;
				color: get-color('dark-grey');
				font-size: 12px;
				letter-spacing: 1.5px;
				font-weight: 500;
			}
		}
	}

}