.other-rooms {
	text-align: center;
	
	&__nav {
		ul {
			margin: 0 0 15px;
			list-style-type: none;
		}
		
		li {
			margin: 0 10px;
			white-space: nowrap;
			
			@include breakpoint(medium) {
				display: inline-block;
			}
			
			&.current button {
				border-bottom: 1px solid get-color(primary);
				color: get-color(primary);
			}
			
			button {
				cursor: pointer;
			}
		}
	}
	
	&__image {
		position: relative;
		background: no-repeat center center;
		background-size: cover;
		min-height: 350px;
		
		a {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	
	&__content {
		padding: 4%;
		
		.multiple-buttons {
			margin: 15px 0;
		}
	}
}