.standard-content {
	padding: 50px 25px;
	
	img {
		width: auto !important;
		max-width: 100% !important;
		height: auto !important;
		padding: 5px;
	}

	h2 {
		text-align: center;
		color: get-color('gold');
		text-transform: uppercase;
		padding: 0 50px;
		margin: 0 auto 50px;

		@include breakpoint(small only) {
			padding: 0 25px;
		}
	}
	
	h3 {
		line-height: rem-calc(30);
		text-transform: uppercase;
		padding: 0 50px;
		margin: 0 auto 25px;

		@include breakpoint(small only) {
			padding: 0 25px;
		}
	}

	ul,ol {
		padding: 0 50px;
		margin: 0 0 25px 15px;

		@include breakpoint(small only) {
			padding: 0 25px;
		}

		li {
			font-family: $body-font;
			font-size: rem-calc(16);
			line-height: rem-calc(26);
			color: get-color('dark-grey');
		}
	}

	p {
		padding: 0 50px;
		margin: 0 auto 25px;

		@include breakpoint(small only) {
			padding: 0 25px;
		}

		a {
			color: get-color('gold');
			text-decoration: underline;

			&:hover {
				color: get-color('bronze');
			}
		}
	}
	
	&__text-image-row {
		margin-bottom: 40px;
	}
	
	&__quote {
		margin-bottom: 40px;
		padding: 5%;
		text-align: center;
		border: 0;
	}

	.multiple-buttons {
		padding: 0 50px;

		@include breakpoint(small only) {
			padding: 0 25px;
		}
	}
}

.double-image {
	text-align: center;
	padding: 20px 0 20px 0;
}

.double-image img {
	display: inline-block;
	width: auto;
	height: auto;
	margin: 10px;
}