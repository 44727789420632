.detail {
	text-align: center;
	padding: 50px;
	
	@include breakpoint(small only) {
		padding: 30px 0;
	}
	
	&__features {
		width: 100%;
		max-width: 660px;
		margin: 0 auto 25px;
		padding: 0 25px;

		h2 {
			margin-bottom: 50px;
			color: get-color('gold');
			text-transform: uppercase;
		}
		
		&-list {

			column-count: 2;
			width: 100% !important; 
		
			@include breakpoint(medium) {
				text-align: left;
			}
			
			&--additional {
				@include breakpoint(small only) {
					display: none;
				}
			}
			
			ul {
				margin: 0;
				padding: 0 25px;

				li {
					font-family: $body-font;
					font-size: rem-calc(16);
					line-height: rem-calc(26);
					color: get-color('dark-grey');
				}
			}
		}
		
		&-toggle {
			display: block;
			margin: 25px auto;
			cursor: pointer;
			color: get-color('dark-grey');
			text-transform: uppercase;
			font-family: $body-font;
			letter-spacing: 1px;
			font-size: rem-calc(14);
			line-height: rem-calc(30);
			font-weight: 500;
		}
	}
	
	&__cta {

		a.button {
			margin-bottom: 0;
		}
	}
	
	&__rate, &__rate big {
		margin-bottom: 15px;
		color: get-color('dark-grey');
		text-transform: uppercase;
		font-family: $body-font;
		letter-spacing: 1px;
		font-size: rem-calc(14);
		line-height: rem-calc(30);
		font-weight: 500;
		margin-bottom: 25px;
	}
}

body.offer-details {

	.detail {

		&__features {

			h2 {
				color: #fff;
			}

			&-toggle {
				color: #fff;
			}
		}

		&__rate, &__rate big {
			color: #fff;
		}

		&__cta {

			a.button {
				color: #fff;
			}
		}
	}
}