.treatments {
	margin-bottom: 80px;
	
	&__nav {
		text-align: center;
		margin-bottom: 30px;
		
		.accordionButton {
			&::after {
				content: '>';
				display: inline-block;
				margin-left: 5px; 
				transition: all 0.3s ease-in-out;
			}
			
			&.on::after {
				transform: rotate(90deg);
			}
		}
		 
		.accordionContent {
			@include breakpoint(medium) {
				display: block !important; 
			}
		}
		
		ul {
			margin: 0;
			list-style-type: none; 
		}
		
		li {
			margin: 5px;
			
			@include breakpoint(medium) {
				display: inline-block;
			} 
		}
		
		a {
			white-space: nowrap; 
		}
	}
	
	&__group {
		margin-bottom: 50px;
		
		h2 {
			a,
			a:link,
			a:visited,
			a:hover,
			a:active {
				color: inherit;
				cursor: default; 
			}
		}
	}
	
	&__info {
		&-price {
			@include breakpoint(small only) {
				text-align: right; 
			}
		}
	}
	
	&__description {
		padding: 20px 0;
		
		p {
			max-width: 600px;
		}
	}
	
	&__cta {
		padding-bottom: 20px;
	}
}