.accordion {
	padding: 50px 25px;
	

	&__inner {
	}
	
	@include breakpoint(small only) {
		margin: 0 15px 30px;
	}
	
	.accordionButton {
		position: relative;
		border-top: 2px solid map-get($foundation-palette, 'gold' );
		padding: 25px 40px 25px 0;
		cursor: pointer;
		margin: 0 auto;

		h3 {
			font-family: $heading-font;
			font-size: rem-calc(30);
			line-height: rem-calc(35);
			margin-bottom: 10px;
		}
		
		&::after {
			content: '';
			position: absolute;
			top: 30px;
			right: 0px;
			width: 30px;
			height: 30px;
			transition: all 0.3s ease-in-out;
			background: url('img/gleddoch-close-gold.svg') no-repeat center;
			background-size: 30px;
			transform: rotate(45deg);
		}
		
		&.on::after {
			transform: rotate(0deg);
		}
	}

	.accordionContent {
		max-width: 680px;
		width: 100%;
	}
	
	&__image {
		img {
			width: 100%;
		}
	}
	
	&__content {
		padding: 25px 0;
	

		li {
			font-family: $body-font;
			list-style: none;
			padding: 10px 0;
		}
		
		.multiple-buttons {
			text-align: left;
		}
	}
}