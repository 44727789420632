.logo-carousel {
	text-align: center;
	background-color: rgba(get-color('beige'),0.3);
	padding: 50px 25px;

	&__wrap {
		max-width: 1260px;
		width: 100%;
		margin: 0 auto;
	}

	&__intro {
		margin: 0px auto 50px;
		max-width: 720px;
		width: 100%;
		padding: 0 37px;

		@include breakpoint(small only) {
			padding: 0;
		}

		h2 {
			color: get-color('gold');
			text-transform: uppercase;
			margin-bottom: 25px;
		}

		p {
			margin-bottom: 25px;
		}
	}
	
	&__inner {
		position: relative;
		padding: 0 25px;
		margin: 20px auto 0;
		max-width: 720px;
		width: 100%;
		
		@include breakpoint(small only) {
			max-width: 320px;
			margin: 0 auto;
		}
		
		.slick-arrow {
			position: absolute;
			top: 50%;
			width: 42px;
			height: 8px;
			text-indent: -12345px;
			transform: translateY(-50%);
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			
			&.slick-prev {
				left: -25px;
				background: url('img/gleddoch-arrow-left-gold.svg') no-repeat;
				background-position: left 10px center;

				@include breakpoint(medium only) {
					left: 0;
				}

				@include breakpoint(small only) {
					left: 35px;
					top: auto;
					bottom: -25px;
				}
				
				&:hover {
					background-position: center;
				}
			}
			
			&.slick-next {
				right: -25px;
				background: url('img/gleddoch-arrow-right-gold.svg') no-repeat;
				background-position: right 10px center;

				@include breakpoint(medium only) {
					right: 0;
				}

				@include breakpoint(small only) {
					right: 35px;
					top: auto;
					bottom: -25px;
				}
				
				&:hover {
					background-position: center;
				}
			}
		}
	}
	
	&__logo {
		padding: 0 12.5px;
		
		@include breakpoint(small only) {
			padding: 5px;
		}
		
		img {
			width: 100%;
		}
	}
}