.detail-links {
	margin-bottom: 30px;
	
	ul {
		margin: 0;
		list-style-type: none;
		text-align: center;  
	}
	
	li {
		display: inline;
		margin: 0 10%; 
	}
	
	a {
		font-family: $header-font-family;
		font-size: 20px; 
		white-space: nowrap; 
	}
}