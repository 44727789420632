

.table {
  background-color: #fff;

  &__inner {
    max-width: 800px;
    width: 100%;
    margin: auto;
    padding: 70px 0 70px 0;
  }

  &__subheading {
    width: 150px !important;

    
  }


  table, th, td {
  border: 1px solid #bfa677;
  text-align: center;
  font-size: 15px;
  font-family: $body-font;
  background-color: #fff;
 
}

table {
  // margin-bottom: 0!important;
}

&__mobile {
  padding: 0 10px;

  table {
    margin-bottom: 30px;
  }
}

th, td {
  font-weight: normal;
}

th.table__heading {
  font-weight: 600;
}

td {
  position: relative;
  @include breakpoint (large up) {
    padding-left: 70px;
  }
  
}

td span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #bfa677;
  color: #fff;
  height: 100%;
  padding-top: 10px;
  max-width: 70px;
  width: 100%;
  text-align: center;
}
}
