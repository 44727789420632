.exclusive-use {

	padding-bottom: 4rem;

	&__inner {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 25px;

		@include breakpoint(large) {
			padding: 0 0px;
		}
	}

	&__image-wrapper {
		text-align: center;
	}

	&__image {
		max-width: 500px;
		margin: 0 auto;
	}

	&__header {
		text-align: center;
		color: get-color('gold');
		text-transform: uppercase;
		margin: 0 auto 50px;
	}

	&__text {

		text-align: center;
		max-width: 800px;
		margin: 0 auto;

		p {

		}

		a {
			color: get-color('gold');
		}
	}


}